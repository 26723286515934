// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/Animations/Rockets/rocket1.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/Animations/Rockets/rocket2.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../images/Animations/Rockets/rocket3.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../images/Animations/Rockets/rocket4.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../images/Animations/Rockets/rocket5.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes rocketFrames {
    0% { background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); }
    20% { background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}); }
    40% { background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___}); }
    60% { background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___}); }
    80% { background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___}); }
    100% { background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); }
}

.space-container{
    position: absolute;
    width: 100%;
    height: 100%;
}

.rocket-container {
    position: absolute;
    width: 76px;
    height: 134px;
    z-index: 4;
}

.rocket-image {
    position: absolute;
    width: 76px;
    height: 134px;
    background-size: cover;
    animation: rocketFrames 0.5s steps(5) infinite;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/Rocket.css"],"names":[],"mappings":"AAAA;IACI,KAAK,yDAAiE,EAAE;IACxE,MAAM,yDAAiE,EAAE;IACzE,MAAM,yDAAiE,EAAE;IACzE,MAAM,yDAAiE,EAAE;IACzE,MAAM,yDAAiE,EAAE;IACzE,OAAO,yDAAiE,EAAE;AAC9E;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8CAA8C;IAC9C,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":["@keyframes rocketFrames {\n    0% { background-image: url('../images/Animations/Rockets/rocket1.png'); }\n    20% { background-image: url('../images/Animations/Rockets/rocket2.png'); }\n    40% { background-image: url('../images/Animations/Rockets/rocket3.png'); }\n    60% { background-image: url('../images/Animations/Rockets/rocket4.png'); }\n    80% { background-image: url('../images/Animations/Rockets/rocket5.png'); }\n    100% { background-image: url('../images/Animations/Rockets/rocket1.png'); }\n}\n\n.space-container{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n}\n\n.rocket-container {\n    position: absolute;\n    width: 76px;\n    height: 134px;\n    z-index: 4;\n}\n\n.rocket-image {\n    position: absolute;\n    width: 76px;\n    height: 134px;\n    background-size: cover;\n    animation: rocketFrames 0.5s steps(5) infinite;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
