// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attribute-hover-container {
    position: relative;
    width: 150px;
    height: 86px;
    z-index: 10;
}

.attribute-hover-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-user-select: none;
            user-select: none;
}

.attribute-hover-title-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    color: white;
    width: 90%;
    height: 12px;
    font-family: "mishmash";
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.attribute-hover-context-text {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    color: white;
    width: 90%;
    height: 10px;
    font-family: "mishmash";
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/AttributeHover.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".attribute-hover-container {\n    position: relative;\n    width: 150px;\n    height: 86px;\n    z-index: 10;\n}\n\n.attribute-hover-background {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    user-select: none;\n}\n\n.attribute-hover-title-text {\n    position: absolute;\n    top: 20%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 12px;\n    text-align: left;\n    line-height: 100%;\n    margin: 0px;\n    color: white;\n    width: 90%;\n    height: 12px;\n    font-family: \"mishmash\";\n    pointer-events: none;\n    user-select: none;\n}\n\n.attribute-hover-context-text {\n    position: absolute;\n    top: 60%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 10px;\n    text-align: left;\n    line-height: 100%;\n    margin: 0px;\n    color: white;\n    width: 90%;\n    height: 10px;\n    font-family: \"mishmash\";\n    pointer-events: none;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
