// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.guide-popup-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-user-select: none;
            user-select: none;
}

.guide-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 519px;
    height: 416px;
}

.guide-popup-main-background {
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
            user-select: none;
}

.guide-popup-end-guide-button {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.guide-popup-prev-button {
    position: absolute;
    top: 80%;
    left: 45%;
    transform: translate(-50%, -50%);
}

.guide-popup-next-button {
    position: absolute;
    top: 80%;
    left: 55%;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/games/components/Popups/GuidePopup.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,SAAS;IACT,QAAQ;IACR,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC","sourcesContent":[".guide-popup-container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 20;\n}\n\n.guide-popup-mask {\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.6);\n    user-select: none;\n}\n\n.guide-popup-main-container {\n    position: absolute;\n    transform: translate(-50%, -50%);\n    left: 50%;\n    top: 50%;\n    width: 519px;\n    height: 416px;\n}\n\n.guide-popup-main-background {\n    width: 100%;\n    height: 100%;\n    user-select: none;\n}\n\n.guide-popup-end-guide-button {\n    position: absolute;\n    top: 80%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.guide-popup-prev-button {\n    position: absolute;\n    top: 80%;\n    left: 45%;\n    transform: translate(-50%, -50%);\n}\n\n.guide-popup-next-button {\n    position: absolute;\n    top: 80%;\n    left: 55%;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
