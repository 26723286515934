// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html, #root {
    width: max(100%, 960px);
    height: max(100%, 400px);
    margin: 0;
    display: flex;
    flex-direction: column;
}

img {
    -webkit-user-drag: none;
    user-drag: none;
}

.middle-container {
    position: absolute;
    display: flex;
    top: 60px;
    width: max(100%, 960px);
    height: calc(max(100%, 400px) - 60px);
}`, "",{"version":3,"sources":["webpack://./src/games/components/Gameplay.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wBAAwB;IACxB,SAAS;IACT,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,qCAAqC;AACzC","sourcesContent":["body, html, #root {\n    width: max(100%, 960px);\n    height: max(100%, 400px);\n    margin: 0;\n    display: flex;\n    flex-direction: column;\n}\n\nimg {\n    -webkit-user-drag: none;\n    user-drag: none;\n}\n\n.middle-container {\n    position: absolute;\n    display: flex;\n    top: 60px;\n    width: max(100%, 960px);\n    height: calc(max(100%, 400px) - 60px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
