// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/backgrounds/welcome.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html, #root {
    width: 100%;
    height: 100%;
    background-color: black;
}

.welcome-page-container{
    height: 100%;
    width: -moz-fit-content;
    width: fit-content;
    max-height: min(100%, calc(100vw *  1080 / 1920));
    aspect-ratio: 1920 / 1080;
    background-color: lightblue;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.welcome-page-background{
    height: 100%;
    width: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    -webkit-user-select: none;
            user-select: none;
}

.welcome-page-background-filter{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
}

.welcome-page-play-button{
    position: absolute;
    width: 16%;
    height: 14.5%;
    top: 47%;
    left: 36.5%;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/games/components/WelcomePage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;IAClB,iDAAiD;IACjD,yBAAyB;IACzB,2BAA2B;IAC3B,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yDAA0D;IAC1D,wBAAwB;IACxB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,QAAQ;IACR,WAAW;IACX,gCAAgC;AACpC","sourcesContent":["body, html, #root {\n    width: 100%;\n    height: 100%;\n    background-color: black;\n}\n\n.welcome-page-container{\n    height: 100%;\n    width: fit-content;\n    max-height: min(100%, calc(100vw *  1080 / 1920));\n    aspect-ratio: 1920 / 1080;\n    background-color: lightblue;\n    position: relative;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.welcome-page-background{\n    height: 100%;\n    width: 100%;\n    background-image: url('../images/backgrounds/welcome.png');\n    background-size: contain;\n    user-select: none;\n}\n\n.welcome-page-background-filter{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: rgba(0, 0, 0, 0.85);\n}\n\n.welcome-page-play-button{\n    position: absolute;\n    width: 16%;\n    height: 14.5%;\n    top: 47%;\n    left: 36.5%;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
