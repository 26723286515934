// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes moveAndFade {
    0% {
      top: 0px;
      opacity: 1;
    }
    80% {
      top: 4px;
      opacity: 0.8;
    }
    100% {
      top: 5px;
      opacity: 0;
    }
}

.resource-change-amount-animation-container {
    position: absolute;
    width: 40px;
    height: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: moveAndFade 1s linear forwards;
}

.resource-change-amount-animation-positive-text, .resource-change-amount-animation-negative-text {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    width: 40px;
    height: 12px;
    font-family: "mishmash";
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.resource-change-amount-animation-positive-text {
    color: rgb(85, 221, 85);
}

.resource-change-amount-animation-negative-text {
    color: rgb(220, 36, 36);
}`, "",{"version":3,"sources":["webpack://./src/games/components/ResourceChangeAmountAnimation.css"],"names":[],"mappings":";AACA;IACI;MACE,QAAQ;MACR,UAAU;IACZ;IACA;MACE,QAAQ;MACR,YAAY;IACd;IACA;MACE,QAAQ;MACR,UAAU;IACZ;AACJ;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,yCAAyC;AAC7C;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["\n@keyframes moveAndFade {\n    0% {\n      top: 0px;\n      opacity: 1;\n    }\n    80% {\n      top: 4px;\n      opacity: 0.8;\n    }\n    100% {\n      top: 5px;\n      opacity: 0;\n    }\n}\n\n.resource-change-amount-animation-container {\n    position: absolute;\n    width: 40px;\n    height: 12px;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    animation: moveAndFade 1s linear forwards;\n}\n\n.resource-change-amount-animation-positive-text, .resource-change-amount-animation-negative-text {\n    position: absolute;\n    top: 50%;\n    left: 55%;\n    transform: translate(-50%, -50%);\n    font-size: 12px;\n    text-align: center;\n    line-height: 100%;\n    margin: 0px;\n    width: 40px;\n    height: 12px;\n    font-family: \"mishmash\";\n    pointer-events: none;\n    user-select: none;\n}\n\n.resource-change-amount-animation-positive-text {\n    color: rgb(85, 221, 85);\n}\n\n.resource-change-amount-animation-negative-text {\n    color: rgb(220, 36, 36);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
