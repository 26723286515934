// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide-popup-resource-content-title {
    position: absolute;
    top: 15%;
    left: 30%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 50%;
    height: 10%;
    font-family: "mishmash";
    color: #5CFFFF;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.guide-popup-resource-content-text {
    position: absolute;
    top: 45%;
    left: 28%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 46%;
    height: 55%;
    font-family: "mishmash";
    color: white;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.guide-popup-resource-image {
    position: absolute;
    width: 260px;
    height: 324px;
    left: 70%;
    top: 41%;
    transform: translate(-50%, -50%);
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/Guides/GuideResource.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,UAAU;IACV,WAAW;IACX,uBAAuB;IACvB,cAAc;IACd,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,UAAU;IACV,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".guide-popup-resource-content-title {\n    position: absolute;\n    top: 15%;\n    left: 30%;\n    transform: translate(-50%, -50%);\n    font-size: 15px;\n    overflow: auto;\n    text-align: left;\n    line-height: 100%;\n    margin: 0px;\n    width: 50%;\n    height: 10%;\n    font-family: \"mishmash\";\n    color: #5CFFFF;\n    pointer-events: none;\n    user-select: none;\n}\n\n.guide-popup-resource-content-text {\n    position: absolute;\n    top: 45%;\n    left: 28%;\n    transform: translate(-50%, -50%);\n    font-size: 12px;\n    overflow: auto;\n    text-align: left;\n    line-height: 100%;\n    margin: 0px;\n    width: 46%;\n    height: 55%;\n    font-family: \"mishmash\";\n    color: white;\n    pointer-events: none;\n    user-select: none;\n}\n\n.guide-popup-resource-image {\n    position: absolute;\n    width: 260px;\n    height: 324px;\n    left: 70%;\n    top: 41%;\n    transform: translate(-50%, -50%);\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
