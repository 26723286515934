// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-current-frame-container {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 174px;
    height: 124px;
}

.main-current-frame-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-user-select: none;
            user-select: none;
}

.main-selecting-frame-container {
    position: absolute;
    width: 397px;
    height: 396px;
    left: 46px;
    top: 44px;
}

@keyframes selecting-shining {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
}

.main-selecting-frame-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: selecting-shining 1s infinite ease-in-out;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/MainMenuSelectingFrame.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,UAAU;IACV,SAAS;AACb;;AAEA;IACI,KAAK,UAAU,EAAE;IACjB,MAAM,YAAY,EAAE;IACpB,OAAO,UAAU,EAAE;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,oDAAoD;IACpD,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".main-current-frame-container {\n    position: absolute;\n    transform: translate(-50%, -50%);\n    width: 174px;\n    height: 124px;\n}\n\n.main-current-frame-image {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    user-select: none;\n}\n\n.main-selecting-frame-container {\n    position: absolute;\n    width: 397px;\n    height: 396px;\n    left: 46px;\n    top: 44px;\n}\n\n@keyframes selecting-shining {\n    0% { opacity: 1; }\n    50% { opacity: 0.7; }\n    100% { opacity: 1; }\n}\n\n.main-selecting-frame-image {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    animation: selecting-shining 1s infinite ease-in-out;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
