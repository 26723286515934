// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-menu-warning-container {
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-menu-warning-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    color: rgb(236, 57, 57);
    width: 100%;
    height: 20px;
    font-family: "mishmash";
    pointer-events: none;
    animation: shine 2s infinite alternate linear;
    -webkit-user-select: none;
            user-select: none;
}

@keyframes shine {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }`, "",{"version":3,"sources":["webpack://./src/games/components/MainMenuWarning.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,oBAAoB;IACpB,6CAA6C;IAC7C,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI;MACE,UAAU;IACZ;IACA;MACE,YAAY;IACd;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".main-menu-warning-container {\n    position: absolute;\n    bottom: 0%;\n    width: 100%;\n    height: 7%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.main-menu-warning-text {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 20px;\n    text-align: center;\n    line-height: 100%;\n    margin: 0px;\n    color: rgb(236, 57, 57);\n    width: 100%;\n    height: 20px;\n    font-family: \"mishmash\";\n    pointer-events: none;\n    animation: shine 2s infinite alternate linear;\n    user-select: none;\n}\n\n@keyframes shine {\n    0% {\n      opacity: 1;\n    }\n    50% {\n      opacity: 0.5;\n    }\n    100% {\n      opacity: 1;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
