// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redeem-display-empty-container {
    position: relative;
    width: 240px;
    height: 70px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.redeem-display-empty-background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 227px;
    height: 60px;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/RedeemDisplayEmpty.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,YAAY;IACZ,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".redeem-display-empty-container {\n    position: relative;\n    width: 240px;\n    height: 70px;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.redeem-display-empty-background {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 227px;\n    height: 60px;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
