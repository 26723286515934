// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.diff-resources-info-grid{
    width: 90%;
    height: 85%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/games/components/DiffResourcesInfo.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,gCAAgC;AACpC","sourcesContent":[".diff-resources-info-grid{\n    width: 90%;\n    height: 85%;\n    top: 50%;\n    left: 50%;\n    position: absolute;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
