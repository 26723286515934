// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-info-container{
    position: absolute;
    width: 202px;
    height: 58px;
    left: calc(max(100%, 960px) - 210px);
    top: 0px;
}

.account-info-background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
            user-select: none;
}

.account-info-account-text, .account-info-key-text, .account-info-player-id-text{
    position: absolute;
    font-size: 8px;
    margin: 0;
    color: white;
    left: 2.5%;
    width: 95%;
    height: 8px;
    font-family: "mishmash";
    color: #5CFFFF;
    white-space: break-spaces;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.account-info-account-text{
    top: 25%;
}

.account-info-key-text{
    top: 50%;
}

.account-info-player-id-text{
    top: 75%;
}`, "",{"version":3,"sources":["webpack://./src/games/components/AccountInfo.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,oCAAoC;IACpC,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,SAAS;IACT,YAAY;IACZ,UAAU;IACV,UAAU;IACV,WAAW;IACX,uBAAuB;IACvB,cAAc;IACd,yBAAyB;IACzB,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,QAAQ;AACZ","sourcesContent":[".account-info-container{\n    position: absolute;\n    width: 202px;\n    height: 58px;\n    left: calc(max(100%, 960px) - 210px);\n    top: 0px;\n}\n\n.account-info-background {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n    height: 100%;\n    user-select: none;\n}\n\n.account-info-account-text, .account-info-key-text, .account-info-player-id-text{\n    position: absolute;\n    font-size: 8px;\n    margin: 0;\n    color: white;\n    left: 2.5%;\n    width: 95%;\n    height: 8px;\n    font-family: \"mishmash\";\n    color: #5CFFFF;\n    white-space: break-spaces;\n    pointer-events: none;\n    user-select: none;\n}\n\n.account-info-account-text{\n    top: 25%;\n}\n\n.account-info-key-text{\n    top: 50%;\n}\n\n.account-info-player-id-text{\n    top: 75%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
