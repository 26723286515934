// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spend-resource-container {
    position: absolute;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
}

.spend-resource-animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.spend-resource-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-user-select: none;
            user-select: none;
}

.spend-resource-amount-animation-container {
    position: absolute;
    width: 40px;
    height: 12px;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/games/components/SpendResource.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gCAAgC;AACpC","sourcesContent":[".spend-resource-container {\n    position: absolute;\n    width: 35px;\n    height: 35px;\n    transform: translate(-50%, -50%);\n}\n\n.spend-resource-animation-container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n}\n\n.spend-resource-image {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    user-select: none;\n}\n\n.spend-resource-amount-animation-container {\n    position: absolute;\n    width: 40px;\n    height: 12px;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
