// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.creature-reboot-button{
    width: 15.6%;
    height: 14.8%;
    bottom: 7%;
    left: 10%;
    position: absolute;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/games/components/Buttons/CreatureRebootButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,UAAU;IACV,SAAS;IACT,kBAAkB;IAClB,gCAAgC;AACpC","sourcesContent":[".creature-reboot-button{\n    width: 15.6%;\n    height: 14.8%;\n    bottom: 7%;\n    left: 10%;\n    position: absolute;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
