// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.diff-resource-display-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.diff-resource-display-image {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 28%;
    height: 60%;
    -webkit-user-select: none;
            user-select: none;
}

.diff-resource-display-positive-text, .diff-resource-display-zero-text, .diff-resource-display-negative-text {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    text-align: right;
    line-height: 8px;
    margin: 0px;
    width: 60%;
    height: 8px;
    font-family: "mishmash";
    -webkit-user-select: none;
            user-select: none;
}

.diff-resource-display-positive-text {
    color: rgb(85, 221, 85);
}

.diff-resource-display-zero-text{
    color: white;
}

.diff-resource-display-negative-text {
    color: rgb(220, 36, 36);
}`, "",{"version":3,"sources":["webpack://./src/games/components/DiffResourceDisplay.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,UAAU;IACV,WAAW;IACX,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,UAAU;IACV,WAAW;IACX,uBAAuB;IACvB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".diff-resource-display-container {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.diff-resource-display-image {\n    position: absolute;\n    top: 20%;\n    left: 10%;\n    width: 28%;\n    height: 60%;\n    user-select: none;\n}\n\n.diff-resource-display-positive-text, .diff-resource-display-zero-text, .diff-resource-display-negative-text {\n    position: absolute;\n    top: 50%;\n    left: 70%;\n    transform: translate(-50%, -50%);\n    font-size: 8px;\n    text-align: right;\n    line-height: 8px;\n    margin: 0px;\n    width: 60%;\n    height: 8px;\n    font-family: \"mishmash\";\n    user-select: none;\n}\n\n.diff-resource-display-positive-text {\n    color: rgb(85, 221, 85);\n}\n\n.diff-resource-display-zero-text{\n    color: white;\n}\n\n.diff-resource-display-negative-text {\n    color: rgb(220, 36, 36);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
