// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/Animations/NewPrograms/card_30.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-program-animation-container{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.new-program-animation-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.new-program-animation-program-container {
    position: absolute;
    width: 165px;
    height: 125px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.new-program-animation-main-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: NewProgramAnimation 2s linear forwards;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
}

@keyframes SpinAnimation {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
}

.new-program-animation-spin-image {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: SpinAnimation 5s linear infinite;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/NewProgramAnimation.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,SAAS;IACT,QAAQ;IACR,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,iDAAiD;IACjD,yDAAqE;IACrE,sBAAsB;AAC1B;;AAEA;IACI;MACE,6CAA6C;IAC/C;IACA;MACE,+CAA+C;IACjD;AACJ;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,2CAA2C;IAC3C,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".new-program-animation-container{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 20;\n}\n\n.new-program-animation-mask {\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.6);\n}\n\n.new-program-animation-program-container {\n    position: absolute;\n    width: 165px;\n    height: 125px;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.new-program-animation-main-animation {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    animation: NewProgramAnimation 2s linear forwards;\n    background-image: url(\"../images/Animations/NewPrograms/card_30.png\");\n    background-size: cover;\n}\n\n@keyframes SpinAnimation {\n    0% {\n      transform: translate(-50%, -50%) rotate(0deg);\n    }\n    100% {\n      transform: translate(-50%, -50%) rotate(360deg);\n    }\n}\n\n.new-program-animation-spin-image {\n    position: absolute;\n    width: 250px;\n    height: 250px;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    animation: SpinAnimation 5s linear infinite;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
