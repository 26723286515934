// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redeem-menu-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 519px;
    height: 416px;
}

.redeem-menu-background {
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
            user-select: none;
}

.redeem-resource-title-text {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    text-align: center;
    line-height: 15px;
    margin: 0px;
    width: 80%;
    height: 15px;
    font-family: "mishmash";
    color: white;
    -webkit-user-select: none;
            user-select: none;
}

.redeem-menu-grid{
    width: 480px;
    height: 280px;
    top: 52%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.redeem-menu-guide-button {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/games/components/RedeemMenu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC","sourcesContent":[".redeem-menu-container {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 519px;\n    height: 416px;\n}\n\n.redeem-menu-background {\n    width: 100%;\n    height: 100%;\n    user-select: none;\n}\n\n.redeem-resource-title-text {\n    position: absolute;\n    top: 12%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 15px;\n    text-align: center;\n    line-height: 15px;\n    margin: 0px;\n    width: 80%;\n    height: 15px;\n    font-family: \"mishmash\";\n    color: white;\n    user-select: none;\n}\n\n.redeem-menu-grid{\n    width: 480px;\n    height: 280px;\n    top: 52%;\n    left: 50%;\n    position: absolute;\n    transform: translate(-50%, -50%);\n}\n\n.redeem-menu-guide-button {\n    position: absolute;\n    top: 80%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
