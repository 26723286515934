// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-hover-container {
    position: absolute;
    width: 94px;
    height: 70px;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.program-hover-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-user-select: none;
            user-select: none;
}

.program-hover-resource-grid{
    position: absolute;
    transform: translate(-50%, -50%);
    width: 88px;
    height: 64px;
    top: 50%;
    left: 50%;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/ProgramHover.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gCAAgC;IAChC,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,oBAAoB;AACxB","sourcesContent":[".program-hover-container {\n    position: absolute;\n    width: 94px;\n    height: 70px;\n    transform: translate(-50%, -50%);\n    z-index: 10;\n}\n\n.program-hover-background {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    user-select: none;\n}\n\n.program-hover-resource-grid{\n    position: absolute;\n    transform: translate(-50%, -50%);\n    width: 88px;\n    height: 64px;\n    top: 50%;\n    left: 50%;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
