// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-resource-display-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 16px;
}

.program-resource-display-image {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    -webkit-user-select: none;
            user-select: none;
}

.program-resource-display-positive-text, 
.program-resource-display-zero-text, 
.program-resource-display-negative-text {
    position: absolute;
    top: 50%;
    left: 28px;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: center;
    line-height: 10px;
    margin: 0px;
    width: 28px;
    height: 10px;
    font-family: "mishmash";
    -webkit-user-select: none;
            user-select: none;
}

.program-resource-display-positive-text {
    color: rgb(85, 221, 85);
}

.program-resource-display-zero-text{
    color: white;
}

.program-resource-display-negative-text {
    color: rgb(220, 36, 36)
}`, "",{"version":3,"sources":["webpack://./src/games/components/ProgramResourceDisplay.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;;;IAGI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;AACJ","sourcesContent":[".program-resource-display-container {\n    position: relative;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 44px;\n    height: 16px;\n}\n\n.program-resource-display-image {\n    position: absolute;\n    top: 50%;\n    left: 8px;\n    transform: translate(-50%, -50%);\n    width: 12px;\n    height: 12px;\n    user-select: none;\n}\n\n.program-resource-display-positive-text, \n.program-resource-display-zero-text, \n.program-resource-display-negative-text {\n    position: absolute;\n    top: 50%;\n    left: 28px;\n    transform: translate(-50%, -50%);\n    font-size: 10px;\n    text-align: center;\n    line-height: 10px;\n    margin: 0px;\n    width: 28px;\n    height: 10px;\n    font-family: \"mishmash\";\n    user-select: none;\n}\n\n.program-resource-display-positive-text {\n    color: rgb(85, 221, 85);\n}\n\n.program-resource-display-zero-text{\n    color: white;\n}\n\n.program-resource-display-negative-text {\n    color: rgb(220, 36, 36)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
