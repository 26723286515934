// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-selector-container{
    position: absolute;
    width: 170px;
    height: 40px;
}

.page-selector-page-number-background {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 25px;
    -webkit-user-select: none;
            user-select: none;
}

.page-selector-page-number-text {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 25px;
    margin: 0px;
    color: white;
    width: 56px;
    height: 25px;
    font-family: "mishmash";
    color: #5CFFFF;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.page-selector-prev-button{
    position: absolute;
    bottom: 25%;
    left: 20%;
    transform: translate(-50%, -50%);
}

.page-selector-next-button{
    position: absolute;
    bottom: 25%;
    left: 80%;
    transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/games/components/PageSelector.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,gCAAgC;IAChC,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,cAAc;IACd,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,gCAAgC;AACpC","sourcesContent":[".page-selector-container{\n    position: absolute;\n    width: 170px;\n    height: 40px;\n}\n\n.page-selector-page-number-background {\n    position: absolute;\n    bottom: 25%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 56px;\n    height: 25px;\n    user-select: none;\n}\n\n.page-selector-page-number-text {\n    position: absolute;\n    bottom: 25%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    text-align: center;\n    line-height: 25px;\n    margin: 0px;\n    color: white;\n    width: 56px;\n    height: 25px;\n    font-family: \"mishmash\";\n    color: #5CFFFF;\n    pointer-events: none;\n    user-select: none;\n}\n\n.page-selector-prev-button{\n    position: absolute;\n    bottom: 25%;\n    left: 20%;\n    transform: translate(-50%, -50%);\n}\n\n.page-selector-next-button{\n    position: absolute;\n    bottom: 25%;\n    left: 80%;\n    transform: translate(-50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
