// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-attributes-container{
    position: absolute;
    display: flex;
    top: 50px;
    left: 260px;
    width: 480px;
    height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/games/components/Attributes.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,WAAW;IACX,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".top-attributes-container{\n    position: absolute;\n    display: flex;\n    top: 50px;\n    left: 260px;\n    width: 480px;\n    height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
