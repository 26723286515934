// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-button, .image-button-disabled {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-user-select: none;
          user-select: none;
}

.image-button-disabled:hover{
  cursor: default;
}
  
.image-button img, .image-button-disabled img {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/Buttons/ImageButton.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,UAAU;EACV,eAAe;EACf,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,gCAAgC;EAChC,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":[".image-button, .image-button-disabled {\n  border: none;\n  background: none;\n  padding: 0;\n  cursor: pointer;\n  position: relative;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  user-select: none;\n}\n\n.image-button-disabled:hover{\n  cursor: default;\n}\n  \n.image-button img, .image-button-disabled img {\n  display: block;\n  width: 100%;\n  height: 100%;\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
