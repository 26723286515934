// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/backgrounds/top_left.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/backgrounds/top_right.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../images/backgrounds/top_mid.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top {
    height: 110px;
    min-height: 110px;
    width: 100%;
    min-width: 960px;
    display: flex;
    font-size: 24px;
    z-index: 10;
}

.top-left, .top-right, .top-middle {
    height: 100%;
    background-size: contain;
}

.top-left {
    width: 480px;
    height: 110px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
}

.top-right {
    width: 480px;
    height: 110px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-repeat: no-repeat;
}

.top-middle {
    flex-grow: 1;
    height: 110px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    background-repeat: repeat-x;
}

.top-upgrade{
    position: absolute;
    width: 58px;
    height: 20px;
    top: 55px;
    left: 580px;
}`, "",{"version":3,"sources":["webpack://./src/games/components/TopMenu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yDAA2D;IAC3D,4BAA4B;AAChC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yDAA4D;IAC5D,4BAA4B;AAChC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yDAA0D;IAC1D,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,WAAW;AACf","sourcesContent":[".top {\n    height: 110px;\n    min-height: 110px;\n    width: 100%;\n    min-width: 960px;\n    display: flex;\n    font-size: 24px;\n    z-index: 10;\n}\n\n.top-left, .top-right, .top-middle {\n    height: 100%;\n    background-size: contain;\n}\n\n.top-left {\n    width: 480px;\n    height: 110px;\n    background-image: url('../images/backgrounds/top_left.png');\n    background-repeat: no-repeat;\n}\n\n.top-right {\n    width: 480px;\n    height: 110px;\n    background-image: url('../images/backgrounds/top_right.png');\n    background-repeat: no-repeat;\n}\n\n.top-middle {\n    flex-grow: 1;\n    height: 110px;\n    background-image: url('../images/backgrounds/top_mid.png');\n    background-repeat: repeat-x;\n}\n\n.top-upgrade{\n    position: absolute;\n    width: 58px;\n    height: 20px;\n    top: 55px;\n    left: 580px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
