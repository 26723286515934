// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-display-container {
    position: relative;
    width: 90px;
    height: 50px;
}

.resource-display-hover-range {
    position: relative;
    width: 100%;
    height: 100%;
}

.resource-display-background {
    position: absolute;
    width: 48px;
    height: 17px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-user-select: none;
            user-select: none;
}

.resource-display-image {
    position: absolute;
    top: 25px;
    left: 10px;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
    -webkit-user-select: none;
            user-select: none;
}

.resource-display-text {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    color: white;
    width: 40px;
    height: 12px;
    font-family: "mishmash";
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

.resource-display-hover-container {
    position: absolute;
    top: 100%;
    left: -10%;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/ResourceDisplay.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".resource-display-container {\n    position: relative;\n    width: 90px;\n    height: 50px;\n}\n\n.resource-display-hover-range {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.resource-display-background {\n    position: absolute;\n    width: 48px;\n    height: 17px;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    user-select: none;\n}\n\n.resource-display-image {\n    position: absolute;\n    top: 25px;\n    left: 10px;\n    transform: translate(-50%, -50%);\n    width: 35px;\n    height: 35px;\n    user-select: none;\n}\n\n.resource-display-text {\n    position: absolute;\n    top: 50%;\n    left: 55%;\n    transform: translate(-50%, -50%);\n    font-size: 12px;\n    text-align: center;\n    line-height: 100%;\n    margin: 0px;\n    color: white;\n    width: 40px;\n    height: 12px;\n    font-family: \"mishmash\";\n    pointer-events: none;\n    user-select: none;\n}\n\n.resource-display-hover-container {\n    position: absolute;\n    top: 100%;\n    left: -10%;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
